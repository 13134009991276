// Generated by Framer (4c611f3)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Slideshow from "https://framerusercontent.com/modules/zvkTOpMSuRzRhLzZZIwG/bwgDNfOkuRQlrlKseDFD/SlideShow.js";
const SlideshowFonts = getFonts(Slideshow);

const cycleOrder = ["HjFhwB04O"];

const variantClassNames = {HjFhwB04O: "framer-v-7q50oq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HjFhwB04O", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-OsMD6", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.header {...restProps} className={cx("framer-7q50oq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HjFhwB04O"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-10ibl6y-container"} layoutDependency={layoutDependency} layoutId={"VPIP5Wje7-container"}><Slideshow alignment={"center"} arrowOptions={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowGap: 10, arrowPadding: 20, arrowPaddingBottom: 0, arrowPaddingLeft: 0, arrowPaddingRight: 0, arrowPaddingTop: 0, arrowPosition: "auto", arrowRadius: 40, arrowShouldFadeIn: false, arrowShouldSpace: true, arrowSize: 40, showMouseControls: true}} autoPlayControl borderRadius={0} direction={"left"} dragControl={false} effectsOptions={{effectsHover: true, effectsOpacity: 1, effectsPerspective: 1200, effectsRotate: 0, effectsScale: 1}} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={10} height={"100%"} id={"VPIP5Wje7"} intervalControl={1.5} itemAmount={1} layoutId={"VPIP5Wje7"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} progressOptions={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 0, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: true}} slots={[]} startFrom={0} style={{height: "100%", width: "100%"}} transitionControl={{damping: 40, stiffness: 200, type: "spring"}} width={"100%"}/></motion.div></motion.header></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-OsMD6 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OsMD6 .framer-uc2yyy { display: block; }", ".framer-OsMD6 .framer-7q50oq { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: 600px; justify-content: center; overflow: hidden; padding: 40px 40px 40px 40px; position: relative; width: 1400px; }", ".framer-OsMD6 .framer-10ibl6y-container { flex: none; height: 200px; position: relative; width: 400px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OsMD6 .framer-7q50oq { gap: 0px; } .framer-OsMD6 .framer-7q50oq > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-OsMD6 .framer-7q50oq > :first-child { margin-top: 0px; } .framer-OsMD6 .framer-7q50oq > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 600
 * @framerIntrinsicWidth 1400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerR9KVTQHym: React.ComponentType<Props> = withCSS(Component, css, "framer-OsMD6") as typeof Component;
export default FramerR9KVTQHym;

FramerR9KVTQHym.displayName = "slider-tablet";

FramerR9KVTQHym.defaultProps = {height: 600, width: 1400};

addFonts(FramerR9KVTQHym, [...SlideshowFonts])